
import { updateAppConfig } from '#app/config'
import { defuFn } from 'defu'

const inlineConfig = {
  "nuxt": {}
}

// Vite - webpack is handled directly in #app/config
if (import.meta.hot) {
  import.meta.hot.accept((newModule) => {
    updateAppConfig(newModule.default)
  })
}

import cfg0 from "/home/forge/deployments/sitefactory/dev/2024-11-15_11-03-37_b9782ef/apps/site-factory/app.config.ts"
import cfg1 from "/home/forge/deployments/sitefactory/dev/2024-11-15_11-03-37_b9782ef/layers/base/app.config.ts"

export default /*@__PURE__*/ defuFn(cfg0, cfg1, inlineConfig)
